import { Flex, QRCode } from "antd";
import { useEffect, useState } from "react";
import { getStudentCode } from "../../../shared/api/edificeRequest";

const StudentAds = () => {
  const [code, setCode] = useState("");

  useEffect(() => {
    const loadStudentCode = async () => {
      const res = await getStudentCode();
      if (res.code) setCode(res.code);
    };
    loadStudentCode();
  }, []);

  return (
    <div>
      <div
        className="serie-title"
        style={{ fontSize: 24, marginLeft: 20, marginTop: 30 }}
      >
        À lire sur l'application mobile
      </div>

      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          zIndex: 10,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              backgroundColor: "#FECF03",
              borderRadius: 8,
              maxWidth: 700,
              paddingBottom: 8,
              paddingRight: 8,
            }}
          >
            <div
              style={{
                backgroundColor: "#3E276F",
                borderRadius: 8,
                maxWidth: 700,
                padding: 20,
                marginTop: -8,
                marginLeft: -8,
              }}
            >
              <Flex gap={18}>
                <Flex
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <QRCode
                    value="https://nabook.onelink.me/d3QQ/3ptadrfm?af_force_deeplink=true"
                    bgColor={"white"}
                  />
                </Flex>
                <Flex style={{ padding: 8 }} vertical>
                  <div
                    className="serie-title"
                    style={{
                      fontSize: 24,
                      color: "white",
                    }}
                  >
                    AVEC L'APPLI MOBILE NABOOK
                  </div>
                  <ul>
                    <li
                      className="resume"
                      style={{
                        fontSize: 16,
                      }}
                    >
                      Lisez un épisode gratuitement par jour
                    </li>
                    <li
                      className="resume"
                      style={{
                        fontSize: 16,
                      }}
                    >
                      Accédez à +300 titres
                    </li>
                    <li
                      className="resume"
                      style={{
                        fontSize: 16,
                      }}
                    >
                      Profitez d'un meilleur confort de lecture
                    </li>
                  </ul>
                  <Flex
                    gap={14}
                    style={{
                      backgroundColor: "#2B1A59",
                      borderRadius: 8,
                      padding: 12,
                      marginTop: 12,
                    }}
                    vertical
                  >
                    {/* <div>Code élève</div> */}
                    <div
                      className="title"
                      style={{
                        color: "#FFCE02",
                        fontSize: 22,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {code}
                    </div>
                    <div
                      className="poppins-italic"
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      💡 Utilisez le code élève de votre enfant pour retrouver
                      les lectures demandées.
                    </div>
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(180deg, rgba(18, 13, 53, 0.40) 0%, var(--nbk-dark, #120D35) 100%)",
          }}
        />
        <img
          style={{ width: "100%", zIndex: 10 }}
          src={require("../../../assets/key-visual.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default StudentAds;

import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Flex } from "antd";

import "../../shared/css/basicStyles.css";
import { useNavigate, useSearchParams } from "react-router-dom";

const EdificeAuth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [code] = useState(searchParams.get("code"));

  useEffect(() => {
    const loadToken = async () => {
      if (code) {
        navigate("/v2/edifice?code=" + code);
      } else {
        var url =
          window.location !== window.parent.location
            ? document.referrer
            : document.location;

        console.log("🧠 ~ loadToken ~ url:", url);

        // Sinon, demander le code oauth
        // TODO: mettre l'url de prod
        window.open(
          `https://recette-ode4.opendigitaleducation.com/auth/oauth2/auth?response_type=code&state=blip&scope=userinfo&client_id=nabook-sso&redirect_uri=https://webapp.nabook.co/v2/edifice/auth`,
          "_self"
        );
      }
    };
    loadToken();
  }, [code, navigate]);

  return (
    <div className="mainContainer">
      <Flex
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
        vertical
        gap={24}
      >
        <CircularProgress color="inherit" />
        <div>{"Authentification en cours..."}</div>
      </Flex>
    </div>
  );
};

export default EdificeAuth;

import { FunctionComponent, useState } from "react";
import { Flex, QRCode } from "antd";

import { Category } from "../../../shared/types/Category";
import { Serie } from "../../../shared/types/Serie";
import Loader from "../../../shared/components/Loader";
import DefaultCoverV2 from "../../../shared/cover/DefaultCoverV2";

import "../../../shared/css/fontStyle.css";
import { useAuth } from "../../../shared/context/TokenContext";
import { Alert } from "@mui/material";
import StudentAds from "./StudentAds";

interface HomeVisualProps {
  cats: Category[];
  isLoading: boolean;
  isPanelOpen: boolean;
  msgErr: string;
  playlist: Serie[] | undefined;
  onClickSerie: (serie: Serie) => void;
  onUnlockSerie: (code: string) => void;
}

const HomeVisual: FunctionComponent<HomeVisualProps> = (
  props: HomeVisualProps
) => {
  const { isEdifice, isTeacher } = useAuth();

  const {
    cats,
    isLoading,
    isPanelOpen,
    msgErr,
    playlist,
    onClickSerie,
    onUnlockSerie,
  } = props;

  const [code, setCode] = useState("");

  const unlockSerie = () => {
    return (
      <Flex
        style={{
          marginTop: 32,
          marginBottom: 32,
        }}
        justify="center"
        wrap="wrap"
        gap="middle"
      >
        <Flex
          style={{
            backgroundColor: "#2B1A59",
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 20,
            paddingLeft: 20,
            borderRadius: 10,
          }}
          gap="middle"
        >
          <Flex
            align="center"
            justify="center"
            onClick={() => {
              window.location.replace(
                "https://nabook.onelink.me/cyS5/r9swckn1"
              );
            }}
          >
            <QRCode
              size={120}
              value="https://nabook.onelink.me/cyS5/r9swckn1"
              bgColor="white"
            />
          </Flex>
          <Flex vertical>
            <div
              className="poppins-semibold"
              style={{
                fontSize: 24,
                color: "white",
              }}
            >
              💡 Installez l'application mobile pour
            </div>
            <ul>
              <li
                className="resume"
                style={{
                  fontSize: 18,
                }}
              >
                Lire un épisode gratuitement par jour
              </li>
              <li
                className="resume"
                style={{
                  fontSize: 18,
                }}
              >
                accéder à des séries exclusives (+300 titres)
              </li>
              <li
                className="resume"
                style={{
                  fontSize: 18,
                }}
              >
                profiter d'un meilleur confort de lecture
              </li>
            </ul>
          </Flex>
        </Flex>
        <Flex
          style={{
            backgroundColor: "#2B1A59",
            paddingBottom: 20,
            paddingTop: 10,
            paddingRight: 20,
            paddingLeft: 20,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <h3 style={{ textAlign: "center" }}>J'ai un code série</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onUnlockSerie(code.toUpperCase());
            }}
          >
            <input
              style={{
                padding: 10,
                borderRadius: 5,
                backgroundColor: "#E8E8E8",
                borderColor: "transparent",
              }}
              value={code.toUpperCase()}
              onChange={(e) => {
                e.preventDefault();
                setCode(`${e.target.value.toUpperCase()}`);
              }}
              maxLength={25}
            />
            {msgErr.length > 0 ? (
              <Alert severity="error">Ce code n'existe pas</Alert>
            ) : null}
            <button type="submit">Valider</button>
          </form>
        </Flex>
      </Flex>
    );
  };

  if (isLoading) return <Loader />;

  if (cats.length > 0)
    return (
      <div
        style={{
          marginTop: 30,
          paddingBottom: 60,
          height: isPanelOpen ? "60vh" : "auto",
          overflow: isPanelOpen ? "hidden" : "auto",
          scrollbarWidth: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!isEdifice || !isEdifice() ? unlockSerie() : null}
        {playlist && playlist.length > 0 ? (
          <div style={{ marginBottom: 30 }}>
            <div
              className="serie-title"
              style={{ fontSize: 24, marginLeft: 20 }}
            >
              Pile à lire
            </div>
            <Flex
              wrap="nowrap"
              style={{
                overflow: "scroll",
                paddingLeft: 15,
                scrollbarWidth: "none",
              }}
            >
              {playlist.map((s: Serie) => {
                return (
                  <DefaultCoverV2
                    onClickCover={() => onClickSerie(s)}
                    key={s.uid}
                    serie={s as Serie}
                  />
                );
              })}
            </Flex>
          </div>
        ) : null}
        {cats.map((c, index) => (
          <div style={{ marginBottom: 30 }} key={index}>
            <div
              className="serie-title"
              style={{ fontSize: 24, marginLeft: 20 }}
            >
              {c.name}
            </div>
            <Flex
              wrap="nowrap"
              style={{
                overflow: "scroll",
                paddingLeft: 15,
                scrollbarWidth: "none",
              }}
            >
              {c.content.map((s: Serie) => {
                return (
                  <DefaultCoverV2
                    onClickCover={() => onClickSerie(s)}
                    key={s.uid}
                    serie={s as Serie}
                  />
                );
              })}
            </Flex>
          </div>
        ))}
        {isEdifice && isTeacher && isEdifice() && !isTeacher() ? (
          <StudentAds />
        ) : null}
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
      }}
    >
      <h2>
        Scannez ce code QR avec votre mobile pour télécharger l'application
      </h2>
      <img
        src={require("../../../assets/qr-code.webp")}
        alt=""
        style={{ width: "20vw", marginTop: 10 }}
      />
      <p>ou</p>
      <a href="https://5f6b0c5f.sibforms.com/serve/MUIEAOPhDiIQfqR6hciMd7COdRm4KLN-lrmaheNr7gOGFYSEniGMJeYXOdjVLq2l7YJKT04Tor2o2niXW6hRclpavG1stMvbtm4Gcm17NoFGRPZTn2i65JJ6jryefn6TPBd46ObBdMFC4dFM-utsoELiVbyVVTboNf1v9Ds-6YuRcddr3PlcecAn8L9CbivBgaASHKvWWaq2jdng">
        Recevez un lien d'installation par email en cliquant ici
      </a>
    </div>
  );
};

export default HomeVisual;

import Cookies from "js-cookie";
import { BASE_URL } from "./constants";
import axios from "axios";

export async function getPreviewAssignation(
  idEpisode: number,
  idClasse: string
) {
  try {
    const token = Cookies.get("user");
    const res = await axios.post(
      `${BASE_URL}/main/edifice/assignement/preview`,
      {
        idClasse,
        idEpisode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[getPreviewAssignation] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function getAssignStudentsForEpisode(
  idEpisode: number,
  idClasse: string
) {
  try {
    const token = Cookies.get("user");
    const res = await axios.post(
      `${BASE_URL}/main/edifice/assignement/students`,
      {
        idClasse,
        idEpisode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[getPreviewAssignation] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function postBulkAssignStudent(
  idEpisode: number,
  idClasse: string,
  students: any
) {
  try {
    const token = Cookies.get("user");
    const res = await axios.post(
      `${BASE_URL}/main/edifice/assignement/bulk/students`,
      {
        idClasse,
        idEpisode,
        students,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.status;
  } catch (e) {
    console.log(`[getPreviewAssignation] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function getSerieAssigned(idClasse: string) {
  try {
    const token = Cookies.get("user");
    const res = await axios.post(
      `${BASE_URL}/main/edifice/assignement/series`,
      {
        idClasse,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[getPreviewAssignation] Il y a eu un problème : ${e}`);
    return null;
  }
}
export async function getAssignTo(idEpisode: number) {
  try {
    const token = Cookies.get("user");
    const res = await axios.post(
      `${BASE_URL}/main/edifice/assignement/unlocked`,
      {
        idEpisode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[getPreviewAssignation] Il y a eu un problème : ${e}`);
    return null;
  }
}

import React, { ChangeEvent, useState } from "react";
import { Button, Modal } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import "../../shared/css/basicStyles.css";
import "../css/Reader.css";
import BubbleVisual from "./Bubble/BubbleVisual";
import { BulleV2 } from "../../shared/types/Bubble";
import BubbleImage from "./Bubble/BubbleImage";
import Close from "../../shared/components/Close";
import Navbar from "./Navbar";
import ListCharacters from "./ListCharacters";
import { Character } from "../../shared/types/Character";
import Loader from "../../shared/components/Loader";
import { Episode } from "../../shared/types/Episode";
import FooterNextEp from "./Bubble/FooterNextEp";
import FooterEndSerie from "./Bubble/FooterEndSerie";
import { Dico } from "../../shared/types/Dico";

type Props = {
  bubbles: BulleV2[] | undefined;
  epName: string;
  idSerie: number;
  characters: Character[];
  nextEp: Episode | undefined;
  isLoading: boolean;
  isBeneylu: boolean;
  setViewableItem: (bubble: BulleV2, index: number) => void;
  params: {
    isDys: boolean;
    fontSize: number;
  };
};

const ReaderVisual = (props: Props) => {
  const {
    bubbles,
    epName,
    idSerie,
    characters,
    isLoading,
    isBeneylu,
    nextEp,
    params,
    setViewableItem,
  } = props;

  const [dys, setDYS] = useState(params.isDys);
  const [fontSize, setFontSize] = useState(params.fontSize);

  const [hidden, setHidden] = useState(false);
  const [openDico, setOpenDico] = useState(false);
  const [dico, setDico] = useState<Dico | null>();

  const handleSwitch = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDYS(checked);
  };

  const handleSlider = (_event: Event, value: number | number[]) => {
    setFontSize(Number(value));
  };

  const handleOk = () => {
    setOpenDico(false);
  };

  const handleClickDico = (dico: Dico) => {
    setDico(dico);
    setOpenDico(true);
  };

  const renderBubble = (b: BulleV2, index: number) => {
    let compToReturn;
    switch (b.type) {
      case "image":
        compToReturn = <BubbleImage bubble={b} />;
        break;
      case "speaker":
        compToReturn = (
          <BubbleVisual
            fontSize={fontSize}
            isDYS={dys}
            bubble={b}
            index={index}
            viewableItem={setViewableItem}
            onDico={handleClickDico}
          />
        );
        break;
    }

    if (bubbles && index === bubbles?.length - 1) {
      return (
        <>
          {compToReturn}
          <div>
            {nextEp !== undefined ? (
              <FooterNextEp
                params={{
                  isDys: dys,
                  fontSize,
                }}
                idSerie={idSerie}
                nextEp={nextEp}
              />
            ) : (
              <FooterEndSerie
                path={isBeneylu && idSerie ? `/serie/${idSerie}` : ".."}
              />
            )}
          </div>
        </>
      );
    }

    return compToReturn;
  };

  if (isLoading) return <Loader />;

  return (
    <div id="mainContainer">
      <div
        id="test"
        style={{
          backgroundColor: "#2b1a59",
          paddingLeft: 24,
          paddingRight: 24,
          // paddingTop: 24,
          maxWidth: "30%",
          // overflow: "hidden",
          scrollbarWidth: "none",
        }}
      >
        <div
          style={{
            marginTop: 10,
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            style={{ backgroundColor: "#3E276F" }}
            type="primary"
            shape="circle"
            size="large"
            icon={!hidden ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onClick={() => {
              setHidden(!hidden);
            }}
          />
        </div>
        {hidden ? null : (
          <div style={{ marginTop: -30 }}>
            <h3>{epName}</h3>
            <Navbar
              params={params}
              handleSlider={handleSlider}
              handleSwitch={handleSwitch}
            />
            <div
              style={{
                marginTop: 24,
                marginBottom: 24,
                backgroundColor: "#120d35",
                height: 1,
              }}
            />
            <ListCharacters characters={characters} />
          </div>
        )}
      </div>
      <div id="reader">
        <div id="msgContainer" style={{ width: hidden ? "70vw" : "50vw" }}>
          {bubbles?.map((b, i) => renderBubble(b, i))}
        </div>
      </div>
      <Modal
        centered
        title={dico?.word}
        open={openDico}
        onOk={handleOk}
        onCancel={handleOk}
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
      >
        <p>{dico?.definition}</p>
      </Modal>
      <Close to={"/"} />
    </div>
  );
};

export default ReaderVisual;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import * as jose from "jose";

import { refreshToken } from "../api/request";

interface AuthProps {
  user: string | null;
  idClasse?: string | null;
  isLoading: boolean;

  isBeneylu?: () => boolean;
  isEdifice?: () => boolean;
  isTeacher?: () => boolean;
  logout?: () => void;
  refresh?: (token?: string | null) => void;
  setIDClasse?: (idClasse: string) => void;
}

const AuthContext = createContext<AuthProps>({
  user: null,
  isLoading: true,
});
export default AuthContext;

export const useAuth = () => useContext(AuthContext);

const BASE_URL_AUTH =
  process.env.NODE_ENV === "production"
    ? "https://auth.nabook.co/signin?redirect_to=https://webapp.nabook.co/"
    : "http://localhost:3003/signin?redirect_to=http://localhost:3001";

export const AuthContextProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<string | null>(null);
  const [idClasse, setClasse] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const logout = useCallback(() => {
    Cookies.remove("user");
    setUser(null);
    setClasse(null);
    window.location.replace(BASE_URL_AUTH);
  }, []);

  const refresh = async (token?: string | null) => {
    if (token) setUser(token);
    else {
      const userProfile = Cookies.get("user");
      if (userProfile) {
        const t = await refreshToken();
        if (!t || !t.isOk) logout();
        Cookies.set("user", t.cookie, {
          sameSite: "None",
          secure: true,
        });
        setUser(t.cookie);
      }
    }
  };

  const isEdifice = () => {
    const userProfile = Cookies.get("user");
    if (!userProfile) return false;
    const decoded: { type?: string } = jose.decodeJwt(userProfile);
    if (decoded.type && decoded.type === "edifice") return true;
    return false;
  };

  const isBeneylu = () => {
    const userProfile = Cookies.get("user");
    if (!userProfile) return false;
    const decoded: { type?: string } = jose.decodeJwt(userProfile);
    if (decoded.type && decoded.type === "beneylu") return true;
    return false;
  };

  const isTeacher = () => {
    const userProfile = Cookies.get("user");
    if (!userProfile) return false;
    const decoded: { idTeacher?: string } = jose.decodeJwt(userProfile);
    if (decoded.idTeacher) return true;
    return false;
  };

  const setIDClasse = (idClasse: string) => {
    setClasse(idClasse);
  };

  useEffect(() => {
    const userProfile = Cookies.get("user");
    if (userProfile) setUser(userProfile);
    setIsLoading(false);
    // else logout();
  }, [logout]);

  return (
    <>
      <AuthContext.Provider
        value={{
          idClasse,
          isLoading,
          user,
          isBeneylu,
          isEdifice,
          isTeacher,
          logout,
          refresh,
          setIDClasse,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export const RequireAuth = () => {
  const { user, isLoading } = useAuth();

  if (!user && !isLoading)
    return <Navigate to={{ pathname: "/logout" }} replace />;

  return <Outlet />;
};

import { Flex, Modal, QRCode } from "antd";
import { Episode } from "../../shared/types/Episode";
import { FunctionComponent, useEffect, useState } from "react";
import { getAssignTo } from "../../shared/api/assignationRequest";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ModalEpisodeEdificeProps {
  episode: Episode | null;
  idSerie: number | null;
  open: boolean;
  onCancel: () => void;
}

const ModalEpisodeEdifice: FunctionComponent<ModalEpisodeEdificeProps> = (
  props: ModalEpisodeEdificeProps
) => {
  const navigation = useNavigate();
  const { episode, idSerie, open, onCancel } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isUnlocked, setIsUnlocked] = useState(false);

  const openEpisode = () => {
    if (episode)
      navigation(
        `/reader/${episode.id}/${
          episode.origStartingNode
            ? episode.origStartingNode
            : episode.startingNode
        }`,
        {
          state: {
            idSerie: idSerie,
            params: { isDys: false, fontSize: 1 },
          },
        }
      );
  };

  useEffect(() => {
    const isAssign = async () => {
      setIsLoading(true);
      if (episode) {
        const res = await getAssignTo(episode?.id);
        console.log("🚀 ~ isAssign ~ res:", res);
        setIsUnlocked(res.isUnlocked);
      }
      setIsLoading(false);
    };

    isAssign();
  }, [episode]);

  const contentIfUnlock = () => {
    return (
      <>
        <div className="title" style={{ color: "black" }}>
          Vous allez accéder à la version web de Nabook.
        </div>
        <div className="resume" style={{ color: "black", marginTop: 12 }}>
          Pour une meilleure expérience de lecture, installez l’application
          mobile Nabook. Connectez-vous avec votre code personnel pour
          retrouverez les lectures assignées par l’enseignant de votre enfant.
        </div>
        <div
          className="serie-title"
          style={{
            color: "black",
            marginTop: 12,
            fontSize: 30,
            textAlign: "center",
          }}
        >
          123456
        </div>
        <Flex
          style={{
            marginTop: 24,
            marginBottom: 24,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCode
            value={
              "https://nabook.onelink.me/d3QQ/3ptadrfm?af_force_deeplink=true"
            }
            bgColor="white"
            color="#120D35"
            style={{
              border: "2px solid #120D35",
            }}
          />
        </Flex>
      </>
    );
  };

  const contentIfLock = () => {
    return (
      <>
        <div className="title" style={{ color: "black" }}>
          Épisode non débloqué.
        </div>
        <div className="resume" style={{ color: "black", marginTop: 12 }}>
          Ton enseignant n'a pas encore débloqué cette épisode. Télécharge
          l'application mobile pour le lire gratuitement.
        </div>
        <Flex
          style={{
            marginTop: 24,
            marginBottom: 24,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCode
            value={
              "https://nabook.onelink.me/d3QQ/3ptadrfm?af_force_deeplink=true"
            }
            bgColor="white"
            color="#120D35"
            style={{
              border: "2px solid #120D35",
            }}
          />
        </Flex>
      </>
    );
  };

  return (
    <Modal
      open={open}
      title={<div style={{ minHeight: 20, maxHeight: 20 }}></div>}
      onCancel={() => {
        onCancel();
      }}
      footer={[
        <Flex key={"footer-1"} justify="center" align="center" gap={24}>
          <Flex onClick={() => {}} style={{ flex: 1, cursor: "pointer" }}>
            <div
              style={{
                width: "100%",
                background: "#FF6501",
                borderRadius: 8,
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "normal",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Installer l'application
              </div>
            </div>
          </Flex>
          {isUnlocked ? (
            <Flex
              onClick={() => {
                openEpisode();
              }}
              style={{ flex: 1, cursor: "pointer" }}
            >
              <div
                style={{
                  width: "100%",
                  background: "#FF6501",
                  borderRadius: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    wordWrap: "break-word",
                    textAlign: "center",
                  }}
                >
                  Continuer sur le web
                </div>
              </div>
            </Flex>
          ) : null}
        </Flex>,
      ]}
    >
      {isLoading ? (
        <CircularProgress style={{ color: "black" }} />
      ) : isUnlocked ? (
        contentIfUnlock()
      ) : (
        contentIfLock()
      )}
    </Modal>
  );
};

export default ModalEpisodeEdifice;

import { useCallback, useEffect } from "react";
import Loader from "../../../shared/components/Loader";
import Cookies from "js-cookie";

const BASE_URL_AUTH =
  process.env.NODE_ENV === "production"
    ? "https://auth.nabook.co/signin?redirect_to=https://webapp.nabook.co/"
    : "http://localhost:3003/signin?redirect_to=http://localhost:3001";

const Logout = () => {
  const logout = useCallback(() => {
    Cookies.remove("user");
    window.location.replace(BASE_URL_AUTH);
  }, []);

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div style={{ backgroundColor: "red" }}>
      <Loader />
    </div>
  );
};

export default Logout;

import Cookies from "js-cookie";
import { BASE_URL } from "./constants";
import axios from "axios";

export async function getNBKtoken(idEdifice: string) {
  // console.log("🚀 ~ getNBKtoken ~ idEdifice:", idEdifice);
  try {
    const res = await axios.post(
      `${BASE_URL}/main/edifice/token`,
      {
        idEdifice,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[getNBKToken] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function getNBKtokenFromCode(code: string) {
  // console.log("🚀 ~ getNBKtokenFromCode ~ code:", code);
  try {
    const res = await axios.post(
      `${BASE_URL}/main/edifice/token/code`,
      {
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[getNBKtokenFromCode] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function getTeacherClasses() {
  const token = Cookies.get("user");
  try {
    const res = await axios.get(`${BASE_URL}/main/edifice/teacher/classes`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (e) {
    console.log(`[getTeacherClasses] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function getStudentCode() {
  const token = Cookies.get("user");
  try {
    const res = await axios.get(`${BASE_URL}/main/edifice/code`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (e) {
    console.log(`[getTeacherClasses] Il y a eu un problème : ${e}`);
    return null;
  }
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import PageSerie from "./series/screen/PageSerie.tsx";
import Reader from "./reader/screen/Reader.tsx";
import {
  AuthContextProvider,
  RequireAuth,
} from "./shared/context/TokenContext.tsx";
import Profil from "./user/screen/ProfilScreen.tsx";
import Sycapay, { getSycapayInfo } from "./checkout/screen/Sycapay.tsx";
import SycapaySuccess from "./checkout/screen/SycapaySuccess.tsx";
import PageCollection from "./collection/screen/Collection.tsx";
import AllCollection from "./collection/screen/AllCollection.tsx";
import OnboardingTeacher from "./edifice/screen/OnboardingTeacher.tsx";
import Home from "./home/screen/v2/Home.tsx";
import Dispatcher from "./edifice/screen/Dispatcher.tsx";
import Logout from "./home/screen/v2/Logout.tsx";
import Dashboard from "./dashboard/screen/Dashboard.tsx";
import OnboardingRelative from "./edifice/screen/OnboardingRelative.tsx";
import Hello from "./edifice/screen/Hello.tsx";
import EdificeAuth from "./edifice/screen/EdificeAuth.tsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      {/* <RouterProvider router={router} /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/edifice/welcome" element={<Hello />} />
          <Route path="/v2/edifice/auth" element={<EdificeAuth />} />
          <Route path="/v2/edifice" element={<Dispatcher />} />
          <Route path="/logout" element={<Logout />} />

          <Route element={<RequireAuth />}>
            <Route path="/" element={<App />} />
            <Route path="/serie/:idSerie" element={<PageSerie />} />
            <Route path="/collection" element={<AllCollection />} />
            <Route
              path="/collection/:idCollection"
              element={<PageCollection />}
            />
            <Route path="/reader/:idEpisode/:nodeId" element={<Reader />} />
            <Route path="/profil" element={<Profil />} />
            <Route
              path="/checkout/sycapay"
              element={<Sycapay />}
              loader={getSycapayInfo}
            />
            <Route path="/checkout/success" element={<SycapaySuccess />} />

            <Route path="/v2/home" element={<Home />} />
            <Route path="/v2/dashboard" element={<Dashboard />} />
            <Route path="/onboard/t" element={<OnboardingTeacher />} />
            <Route path="/onboard/o" element={<OnboardingRelative />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import { Icon } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  to: string;
};

const Close = (props: Props) => {
  const { to } = props;
  return (
    <Link to={to}>
      <div
        style={{
          position: "absolute",
          top: 15,
          right: 30,
        }}
      >
        <Icon sx={{ fontSize: 50, color: "white" }}>cancel</Icon>
      </div>
    </Link>
  );
};

export default Close;

import { FunctionComponent } from "react";

import "../../shared/css/fontStyle.css";
import "../../shared/css/btnNBK.css";
import { CircularProgress } from "@mui/material";

interface BtnNbkProps {
  color?: "primary" | "secondary" | undefined;
  isLoading?: boolean | null;
  txt: string;
  size?: "small" | "medium" | "large" | undefined;
  style?: React.CSSProperties | undefined;
  onClick: () => void;
}

const BtnNbk: FunctionComponent<BtnNbkProps> = (props: BtnNbkProps) => {
  const { isLoading, color, txt, size, style, onClick } = props;

  const getSize = () => {
    switch (size) {
      case "small":
        return "btn-small";
      case "medium":
        return "btn-medium";
      case "large":
      default:
        return "btn-large";
    }
  };

  const getColor = () => {
    switch (color) {
      case "secondary":
        return "#FFCC00";
      case "primary":
      default:
        return "#FF6501";
    }
  };

  return (
    <div
      onClick={onClick}
      className={`btn-nbk ${getSize()}`}
      style={{
        background: getColor(),
        marginTop: 40,
        ...style,
      }}
    >
      {isLoading !== null && isLoading === true ? (
        <CircularProgress style={{ color: "white" }} />
      ) : (
        <div className="txt-btn">{txt}</div>
      )}
    </div>
  );
};

export default BtnNbk;

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Icon } from "@mui/material";
import { Flex } from "antd";

import "../../shared/css/basicStyles.css";
import {
  getNBKtoken,
  getNBKtokenFromCode,
} from "../../shared/api/edificeRequest";
import Cookies from "js-cookie";
import { useAuth } from "../../shared/context/TokenContext";

const Dispatcher = () => {
  const { refresh } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [msgLoading, setMsgLoading] = useState(
    "Récupération des informations depuis l'ENT..."
  );

  const loadTokenID = useCallback(
    async (id: string) => {
      if (isLoading) return;
      setIsLoading(true);
      const tokenNBK = await getNBKtoken(id);
      console.log("🚀 ~ tokenNBK:", tokenNBK);

      Cookies.set("user", tokenNBK.accessToken, {
        sameSite: "None",
        secure: true,
      });

      if (refresh) await refresh(tokenNBK.accessToken);

      if (tokenNBK.created && tokenNBK.type === "teacher")
        navigate("/edifice/welcome?type=teacher");
      else if (tokenNBK.created && tokenNBK.type !== "teacher")
        navigate("/edifice/welcome");
      else {
        if (window && window.parent)
          window.parent.postMessage("webapp_nabook", "*");
        navigate("/v2/home");
      }
    },
    [navigate, refresh, isLoading]
  );
  const loadToken = useCallback(
    async (code: string) => {
      if (isLoading) return;
      setIsLoading(true);
      const tokenNBK = await getNBKtokenFromCode(code);
      // console.log("🚀 ~ tokenNBK:", tokenNBK);

      Cookies.set("user", tokenNBK.accessToken, {
        sameSite: "None",
        secure: true,
      });

      if (refresh) await refresh(tokenNBK.accessToken);

      if (tokenNBK.created && tokenNBK.type === "teacher")
        navigate("/edifice/welcome?type=teacher");
      else if (tokenNBK.created && tokenNBK.type !== "teacher")
        navigate("/edifice/welcome");
      else {
        if (window && window.parent)
          window.parent.postMessage("webapp_nabook", "*");
        navigate("/v2/home");
      }
    },
    [navigate, refresh, isLoading]
  );

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) loadToken(code);
    else if (!code) {
      const id = searchParams.get("id");
      console.log("🚀 ~ useEffect ~ id:", id);
      if (id) loadTokenID(id);
      setError(true);
      setMsgLoading("Impossible de récupérer les données");
    }
  }, [searchParams, loadToken]);

  return (
    <div className="mainContainer">
      <Flex
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
        vertical
        gap={24}
      >
        {error ? (
          <Icon sx={{ fontSize: 50, color: "white" }}>error</Icon>
        ) : (
          <CircularProgress color="inherit" />
        )}
        <div>{msgLoading}</div>
      </Flex>
    </div>
  );
};

export default Dispatcher;

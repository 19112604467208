import { useState } from "react";

import Intro from "../components/Intro";
import { useNavigate } from "react-router-dom";
import CarouselRelative from "../components/CarouselRelative";

const OnboardingRelative = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  if (step === 0)
    return (
      <Intro
        next={() => {
          setStep(step + 1);
        }}
      />
    );

  if (step === 1)
    return (
      <CarouselRelative
        next={() => {
          navigate("/v2/home");
        }}
      />
    );
};

export default OnboardingRelative;

import { Flex, Modal } from "antd";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { getAssignStudentsForEpisode } from "../../shared/api/assignationRequest";
import { CircularProgress } from "@mui/material";
import { Student } from "../../shared/types/Student";
import { useAuth } from "../../shared/context/TokenContext";

interface ModalAssignementsProps {
  idEpisode: number | null;
  open: boolean;
  assign: (students: Student[]) => void;
  close: () => void;
}

const BtnStudents = (props: {
  student: Student;
  onClick: (id: string, assign: boolean) => void;
}) => {
  const { student, onClick } = props;

  return (
    <Flex
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClick(student.id, !student.assigned);
      }}
    >
      <Flex
        style={{
          width: 130,
          height: 52,
          borderRadius: 8,
          background: student.assigned ? "#FECF03" : "#EEF0F1",
          border: student.assigned ? "2px #DDB406 solid" : "",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "black",
            fontSize: 12,
            fontFamily: "Roboto",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {student.name}
        </div>
      </Flex>
    </Flex>
  );
};

const ModalAssignements: FunctionComponent<ModalAssignementsProps> = (
  props: ModalAssignementsProps
) => {
  const { idEpisode, assign, open, close } = props;

  const { idClasse } = useAuth();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [students, setStudents] = useState<Student[]>([]);

  const loadStudents = useCallback(async () => {
    if (idEpisode !== null && idClasse) {
      const res = await getAssignStudentsForEpisode(idEpisode, idClasse);
      console.log("🚀 ~ loadStudents ~ res:", res);
      if (res.students)
        setStudents(
          res.students.map(
            (s: { student: Student; finished: boolean }) => s.student
          )
        );
    }
    setLoading(false);
  }, [idEpisode, idClasse]);

  useEffect(() => {
    setIsOpen(open);
    setSaving(false);
    setLoading(true);
    loadStudents();
  }, [loadStudents, open]);

  const handleCancel = () => {
    close();
  };

  const footer = () => {
    return [
      <Flex key={"footer-1"} justify="center" align="center" gap={10}>
        <Flex style={{ flexGrow: 1, flex: 2 }}>
          <div
            className="poppins-italic"
            style={{
              width: "100%",
            }}
          >
            Les lectures assignées permettent aux élèves de lire en autonomie.{" "}
            Elles apparaissent dans l’application Nabook côté parent et élève.
          </div>
        </Flex>
        <Flex
          onClick={() => {
            setSaving(true);
            assign(students);
          }}
          style={{ flex: 1, cursor: "pointer" }}
        >
          <div
            style={{
              width: "100%",
              background: "#FF6501",
              borderRadius: 8,
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
            }}
          >
            {saving ? (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <CircularProgress
                  style={{ color: "white", width: 20, height: 20 }}
                />
              </div>
            ) : (
              <div
                style={{
                  color: "white",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "normal",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Assigner la lecture
              </div>
            )}
          </div>
        </Flex>
      </Flex>,
    ];
  };

  const title = () => {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <span
          style={{
            color: "black",
            fontSize: 16,
            fontFamily: "Roboto",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
          // TODO: Remplacer ici par le nom et le numéro d'épisode correspondant
        >
          EP1 - Prologue
          <br />
        </span>
        <span
          style={{
            color: "black",
            fontSize: 16,
            fontFamily: "Roboto",
            fontWeight: "700",
            wordWrap: "break-word",
          }}
        >
          À quels élèves souhaitez-vous assigner cette lecture ?
        </span>
      </div>
    );
  };

  const bulkEdit = (select: boolean) => {
    const tmpStudents = students.map((s) => {
      return {
        ...s,
        assigned: select,
      };
    });
    setStudents([...tmpStudents]);
  };

  return (
    <Modal
      open={isOpen}
      title={title()}
      onCancel={handleCancel}
      footer={footer()}
    >
      <Flex gap={10}>
        <div
          onClick={() => {
            bulkEdit(false);
          }}
          style={{
            cursor: "pointer",
            borderRadius: 8,
            border: "1px #FF6501 solid",
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 6,
            paddingBottom: 6,
          }}
        >
          <div
            className="resume"
            style={{
              color: "#FF6501",
              fontSize: 14,
            }}
          >
            Tout désélectionner
          </div>
        </div>
        <div
          onClick={() => {
            bulkEdit(true);
          }}
          style={{
            backgroundColor: "#FFD8BF",
            cursor: "pointer",
            borderRadius: 8,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 6,
            paddingBottom: 6,
          }}
        >
          <div
            className="resume"
            style={{
              color: "#FF6501",
              fontSize: 14,
            }}
          >
            Tout sélectionner
          </div>
        </div>
      </Flex>
      <Flex
        justify="center"
        wrap={"wrap"}
        gap={10}
        style={{
          maxHeight: 500,
          overflow: "scroll",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        {loading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            {students.map((s, i) => (
              <BtnStudents
                key={i}
                student={s}
                onClick={(id: string, assined: boolean) => {
                  if (!saving) {
                    const index = students.findIndex(
                      (st: Student) => st.id === id
                    );
                    students[index].assigned = assined;
                    setStudents([...students]);
                  }
                }}
              />
            ))}
          </>
        )}
      </Flex>
    </Modal>
  );
};

export default ModalAssignements;

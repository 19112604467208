import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Assignation } from "../../shared/types/Assignation";
import { Icon } from "@mui/material";
import ModalAssignements from "../../series/components/ModalAssignements";
import { Flex, message } from "antd";
import { postBulkAssignStudent } from "../../shared/api/assignationRequest";
import DefaultCoverV2 from "../../shared/cover/DefaultCoverV2";

import AssignEpisodeRow from "../../series/components/AssignEpisodeRow";
import { getSerieInfo } from "../../shared/api/request";
import { Serie } from "../../shared/types/Serie";
import { useAuth } from "../../shared/context/TokenContext";

interface PanelAssignationProps {
  data: Assignation;
  onClose: () => void;
}

const PanelAssignation: FunctionComponent<PanelAssignationProps> = (
  props: PanelAssignationProps
) => {
  const { data, onClose } = props;

  const [messageApi, contextHolder] = message.useMessage();
  const { idClasse } = useAuth();

  const [details, setDetails] = useState<Serie | undefined>(undefined);
  const [assignEp, setAssignEp] = useState<number | null>(null);

  const loadDetails = useCallback(async () => {
    const res = await getSerieInfo(`${data.serie.id}`);
    console.log("🌸 ~ loadDetails ~ res:", res);
    setDetails(res);
  }, [data.serie]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  const success = () => {
    loadDetails();
    messageApi.open({
      type: "success",
      content: "L'assignation a été effectuée avec succès",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content:
        "Il y a eu un problème lors de l'assignation. Veuillez réessayer",
    });
  };

  const assign = async (students: any) => {
    if (idClasse) {
      const status = await postBulkAssignStudent(assignEp!, idClasse, students);

      if (status && status === 200) {
        success();
        setAssignEp(null);
      } else error();
    } else error();
  };

  return (
    <div>
      {contextHolder}
      <Flex style={{ padding: 30 }} gap={40}>
        <Flex vertical>
          <Flex>
            <DefaultCoverV2 serie={data.serie} />
          </Flex>

          <div
            style={{
              background: "#3E276F",
              borderRadius: 8,
              marginTop: 14,
              marginRight: 14,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Dernière assignation
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: 22,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  wordWrap: "break-word",

                  marginTop: 4,
                }}
              >
                {data.createAt}
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#3E276F",
              borderRadius: 8,
              marginTop: 14,
              marginRight: 14,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 22,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                {data.countAssignement}
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  marginTop: 4,
                }}
              >
                assignations en cours
              </div>
            </div>
          </div>
        </Flex>
        <Flex style={{ flexGrow: 1, flex: 3, marginTop: 20 }} gap={14} vertical>
          {details?.Seasons &&
            details.Seasons[0] &&
            details.Seasons[0].Episodes &&
            details.Seasons[0].Episodes.map((e, i) => (
              <AssignEpisodeRow
                key={i}
                episode={e}
                assign={(idEpisode: number) => {
                  setAssignEp(idEpisode);
                }}
              />
            ))}
        </Flex>
      </Flex>
      <div
        onClick={onClose}
        style={{ position: "absolute", top: 20, right: 20, cursor: "pointer" }}
      >
        <Icon sx={{ fontSize: 50, color: "black" }}>close_rounded</Icon>
      </div>
      <ModalAssignements
        open={assignEp !== null}
        idEpisode={assignEp}
        assign={(students: any) => {
          assign(students);
        }}
        close={() => setAssignEp(null)}
      />
    </div>
  );
};

export default PanelAssignation;

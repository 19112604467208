import { FunctionComponent, useEffect, useState } from "react";
import { Episode } from "../../shared/types/Episode";
import { Avatar, Badge, Flex, Tooltip } from "antd";
import { Student } from "../../shared/types/Student";
import { getAssignStudentsForEpisode } from "../../shared/api/assignationRequest";
import { Icon } from "@mui/material";
import { useAuth } from "../../shared/context/TokenContext";

interface AssignEpisodeRowProps {
  episode: Episode;
  assign: (idEpisode: number) => void;
}

const AssignEpisodeRow: FunctionComponent<AssignEpisodeRowProps> = (
  props: AssignEpisodeRowProps
) => {
  const { episode, assign } = props;

  const { idClasse } = useAuth();

  const [students, setStudents] = useState<
    { student: Student; finished: boolean }[]
  >([]);

  useEffect(() => {
    // Get assignees
    const loadStudents = async () => {
      if (idClasse) {
        const res = await getAssignStudentsForEpisode(episode.id, idClasse);
        if (res.students)
          setStudents(
            res.students.filter(
              (s: { student: Student; finished: boolean }) => s.student.assigned
            )
          );
      }
    };
    loadStudents();
  }, [episode, idClasse]);

  return (
    <Flex vertical>
      <div
        className="episode-name"
        style={{
          color: "black",
        }}
      >
        EP{episode.number} - {episode.name}
      </div>
      <Flex gap={8} justify="center" align="center">
        <Flex
          style={{
            width: "100%",
            minHeight: 100,
            flexGrow: 1,
            background: "#3E276F",
            borderRadius: 8,
            padding: 20,
            marginTop: 8,
          }}
        >
          {students.length > 0 ? (
            <Flex wrap="wrap" gap={8}>
              {students?.map((s, i) => {
                if (s.finished)
                  return (
                    <Tooltip
                      key={i}
                      title={`${s.student.name} - Lecture terminée`}
                    >
                      <Badge
                        style={{
                          backgroundColor: "#52C41A",
                          border: "0px solid",
                          width: 10,
                          height: 10,
                        }}
                        dot
                        offset={[-4, 4]}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#FFD8BF",
                            color: "#FA541C",
                            fontSize: 16,
                            fontFamily: "Roboto",
                            wordWrap: "break-word",
                            border: "1px solid white",
                          }}
                        >
                          {s.student.initials}
                        </Avatar>
                      </Badge>
                    </Tooltip>
                  );
                return (
                  <Tooltip key={i} title={`${s.student.name}`}>
                    <Avatar
                      style={{
                        backgroundColor: "#FFD8BF",
                        color: "#FA541C",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        wordWrap: "break-word",
                        border: "1px solid white",
                      }}
                    >
                      {s.student.initials}
                    </Avatar>
                  </Tooltip>
                );
              })}
            </Flex>
          ) : (
            <Flex
              style={{
                width: "100%",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="resume">Aucun élève assigné</div>
            </Flex>
          )}
        </Flex>
        <Flex
          onClick={() => {
            assign(episode.id);
          }}
          style={{
            background: "#FFD8BF",
            borderRadius: 8,
            border: "3px #FF6501 solid",
            padding: 8,
            cursor: "pointer",
          }}
          justify="center"
          align="center"
          gap={10}
          vertical
        >
          <Icon sx={{ color: "#FF6501", fontSize: 30 }}>
            add_box_outlined_icon
          </Icon>
          <div
            className="resume"
            style={{
              color: "#FF6501",
              fontSize: 14,
              textAlign: "center",
            }}
          >
            Assigner l’épisode
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AssignEpisodeRow;

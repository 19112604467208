import { FunctionComponent } from "react";

import BtnNbk from "../../shared/components/BtnNBK";

import "../css/style.css";
import "../../shared/css/fontStyle.css";
import { Flex } from "antd";

interface IntroProps {
  next: () => void;
}

const Intro: FunctionComponent<IntroProps> = (props: IntroProps) => {
  const { next } = props;
  return (
    <div className="main">
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginTop: 20,
        }}
      >
        <img
          src={require("../../assets/logo-nbk.png")}
          style={{
            // width: 274,
            // height: 88,
            width: "24vw",
            aspectRatio: 274 / 88,
          }}
          alt=""
        />
      </div>
      <Flex
        vertical
        style={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          zIndex: 100,
        }}
        gap={40}
      >
        <div
          className="serie-title"
          style={{
            textAlign: "center",
            fontSize: 40,
          }}
        >
          LA PLATEFORME DE LECTURE 100% PLAISIR
        </div>

        <BtnNbk txt="Découvrir" onClick={next} />
      </Flex>
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <img
          src={require("../../assets/background-visual.png")}
          style={{
            // width: 274,
            // height: 88,
            width: "100%",
            aspectRatio: 1200 / 434,
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export default Intro;

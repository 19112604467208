const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.nabook.co"
    : "http://localhost:3000";
const PAY_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://pay.nabook.co"
    : "http://localhost:3004";

export { BASE_URL, PAY_BASE_URL };

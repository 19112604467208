import { useCallback, useEffect, useState } from "react";

import { getSerieAssigned } from "../../shared/api/assignationRequest";
import DashboardVisual from "../components/DashboardVisual";
import { Assignation } from "../../shared/types/Assignation";
import HeaderV2 from "../../shared/components/HeaderV2";
import { useAuth } from "../../shared/context/TokenContext";

const Dashboard = () => {
  const { idClasse } = useAuth();

  const [series, setSeries] = useState<Assignation[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const loadSeries = useCallback(async () => {
    setLoading(true);
    if (idClasse) {
      const res = await getSerieAssigned(idClasse);
      setSeries(res.assignements);
    }
    setLoading(false);
  }, [idClasse]);

  useEffect(() => {
    loadSeries();
  }, [loadSeries]);

  return (
    <div className="mainContainer">
      <HeaderV2 selected="dashboard" type="teacher" />
      <DashboardVisual isLoading={isLoading} series={series} />
    </div>
  );
};

export default Dashboard;

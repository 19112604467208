import { Flex } from "antd";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BtnNbk from "../../shared/components/BtnNBK";
import Intro from "../components/Intro";

const Hello = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [type] = useState(searchParams.get("type"));

  return (
    <div className="mainContainer">
      <Flex
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          scrollbarWidth: "none",
        }}
        vertical
        gap={24}
      >
        <Flex
          style={{
            maxWidth: 700,
            height: "100%",
            paddingBottom: 60,
            paddingTop: 24,
            justifyContent: "center",
            alignItems: "center",
          }}
          vertical
        >
          {type !== "teacher" ? (
            <Intro
              next={() => {
                if (window && window.parent)
                  window.parent.postMessage("webapp_nabook", "*");
                navigate("/onboard/o");
              }}
            />
          ) : (
            <Flex
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              gap={80}
              vertical
            >
              <Flex
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                }}
                vertical
              >
                <Flex
                  className="serie-title"
                  style={{
                    fontSize: 64,
                  }}
                >
                  DÉCOUVREZ NABOOK
                </Flex>
                <Flex style={{ fontSize: 25 }} className="resume">
                  votre bibliothèque numérique interactive
                </Flex>
              </Flex>
              <Flex
                style={{
                  backgroundColor: "#FECF03",
                  borderRadius: 8,
                  paddingBottom: 10,
                  paddingRight: 10,
                  zIndex: 10,
                }}
                vertical
                gap={20}
              >
                <Flex
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#3E276F",
                    borderRadius: 8,
                    padding: 24,
                    marginTop: -10,
                    marginLeft: -10,
                  }}
                  vertical
                  gap={30}
                >
                  <Flex
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                    }}
                  >
                    <div>
                      <strong>ONE</strong> a sélectionné pour vous{" "}
                      <strong>24 livres</strong> variés issus de la bibliothèque
                      Nabook.
                    </div>
                  </Flex>
                  <Flex
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                    }}
                  >
                    <div>
                      Avec cette appli, nous avons à cœur de vous aider à{" "}
                      <strong>transmettre le plaisir de lire</strong> à vos
                      élèves en leur offrant une expérience de lecture ludique
                      en classe et à la maison.
                    </div>
                  </Flex>
                </Flex>
              </Flex>
              <BtnNbk
                txt="En savoir plus"
                onClick={() => {
                  if (window && window.parent)
                    window.parent.postMessage("webapp_nabook", "*");
                  navigate("/onboard/t");
                }}
                style={{
                  zIndex: 10,
                }}
              />
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 0,
                }}
              >
                <img
                  src={require("../../assets/background-visual.png")}
                  style={{
                    // width: 274,
                    // height: 88,
                    width: "100%",
                    aspectRatio: 1200 / 434,
                  }}
                  alt=""
                />
              </div>
            </Flex>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default Hello;

import React from "react";
import { Serie } from "../types/Serie";

type Props = {
  serie: Serie;
  onClickCover?: () => void;
  style?: React.CSSProperties | undefined;
};

const DefaultCoverV2 = (props: Props) => {
  const { onClickCover, serie, style } = props;
  return (
    <div
      style={{
        minWidth: 165,
        maxWidth: 165,
        aspectRatio: 165 / 219,
        borderRadius: 8,
        overflow: "hidden",
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        cursor: "pointer",
        ...style,
      }}
      onClick={() => {
        if (onClickCover) onClickCover();
      }}
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#C3C3C3",
        }}
        src={`https://newbrand.s3.eu-west-3.amazonaws.com/series/${serie.uid}/couvertures/${serie.img}`}
        alt=""
      />
    </div>
  );
};

export default DefaultCoverV2;

import { Icon } from "@mui/material";
import { Flex, Select } from "antd";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTeacherClasses } from "../api/edificeRequest";
import { useAuth } from "../context/TokenContext";

interface HeaderV2Props {
  selected: "home" | "dashboard" | "profil";
  type: "teacher" | "user" | "edifice-user";
}

const HeaderV2: FunctionComponent<HeaderV2Props> = (props: HeaderV2Props) => {
  const navigate = useNavigate();
  const { setIDClasse, isEdifice } = useAuth();

  const { selected, type } = props;

  const [classes, setClasses] = useState<{ idClasse: string; name: string }[]>(
    []
  );

  const loadClasses = useCallback(async () => {
    const res = await getTeacherClasses();
    setClasses(res.classes);
    if (setIDClasse && res.classes.length > 0)
      setIDClasse(res.classes[0].idClasse);
  }, [setIDClasse]);

  useEffect(() => {
    if (type === "teacher")
      // Aller chercher toutes l es classes du prof
      loadClasses();
  }, [loadClasses, type]);

  return (
    <Flex
      align="flex-start"
      justify="space-between"
      style={{ marginTop: 30, marginLeft: 20, marginRight: 20 }}
    >
      <Flex align="flex-start" gap={20}>
        <div
          style={{
            height: "100%",
            aspectRatio: 1,
            position: "relative",
            background: selected === "home" ? "#FECF03" : "#3E276F",
            borderRadius: 9999,
            padding: 12,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/v2/home");
          }}
        >
          <Icon
            sx={{
              fontSize: 34,
              color: selected === "home" ? "black" : "white",
            }}
          >
            home_rounded
          </Icon>
        </div>
        {type === "teacher" && classes.length > 0 ? (
          <>
            <div
              style={{
                height: "100%",
                position: "relative",
                background: selected === "dashboard" ? "#FECF03" : "#3E276F",
                borderRadius: 100,
                padding: 20,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/v2/dashboard");
              }}
            >
              <div
                className="txt-btn"
                style={{
                  fontSize: 18,
                  color: selected === "dashboard" ? "black" : "white",
                }}
              >
                Tableau de bord
              </div>
            </div>
            <div
              style={{
                height: "100%",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <div className="resume" style={{ marginBottom: 4 }}>
                Ma classe
              </div>
              <Select
                defaultValue={classes[0]?.idClasse || ""}
                style={{ width: 120 }}
                onChange={(value: string) => {
                  if (setIDClasse) setIDClasse(value);
                }}
                options={classes.map((c) => {
                  return { value: c.idClasse, label: c.name };
                })}
              />
            </div>
          </>
        ) : null}
        {type === "user" ? (
          <div
            style={{
              height: "100%",
              aspectRatio: 1,
              position: "relative",
              background: selected === "profil" ? "#FECF03" : "#3E276F",
              borderRadius: 9999,
              padding: 12,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/profil");
            }}
          >
            <Icon
              sx={{
                fontSize: 34,
                color: selected === "profil" ? "black" : "white",
              }}
            >
              person
            </Icon>
          </div>
        ) : null}
      </Flex>
      {isEdifice && isEdifice() ? null : (
        <img
          style={{ width: 75, height: 75, position: "relative" }}
          src={require("../../assets/logo-app.png")}
          alt="logo nabook"
        />
      )}
    </Flex>
  );
};

export default HeaderV2;

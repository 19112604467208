import { Link } from "react-router-dom";
import { Episode } from "../../../shared/types/Episode";

type Props = {
  nextEp: Episode;
  idSerie: number;
  params: {
    isDys: boolean;
    fontSize: number;
  };
};

const FooterNextEp = (props: Props) => {
  const { nextEp, idSerie, params } = props;
  return (
    <div
      style={{
        justifyContent: "center",
      }}
    >
      <Link
        to={`/reader/${nextEp.id}/${
          nextEp.origStartingNode
            ? nextEp.origStartingNode
            : nextEp.startingNode
        }`}
        state={{ idSerie, params, episode: nextEp }}
        style={{
          justifyContent: "center",
          marginTop: "0.66rem",
          marginBottom: "0.66rem",
          marginLeft: "1rem",
          marginRight: "1rem",
          flexDirection: "row",
          textDecoration: "none",
        }}
      >
        <div
          style={{
            backgroundColor: "#FF6600",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            marginTop: 24,
            marginBottom: 10,
            marginLeft: 40,
            marginRight: 40,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 10,
            flexDirection: "row",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              // fontSize: moderateScale(16),
              fontFamily: "Roboto",
              color: "white",
              textAlign: "center",
            }}
          >
            Lire le prochain épisode
          </p>
          {/* <Icon
        name="controller-next"
        color="white"
        size={EStyleSheet.value('2rem')}
      /> */}
        </div>
      </Link>
    </div>
  );
};

export default FooterNextEp;

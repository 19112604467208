import React, { FunctionComponent, useState } from "react";
import { Flex, message } from "antd";
import { Icon } from "@mui/material";

import BtnNbk from "../../shared/components/BtnNBK";
import TagSerie from "../../shared/components/TagSerie";

import "../css/style.css";
import "../../shared/css/fontStyle.css";
import { addToReadingList } from "../../shared/api/serieRequest";

interface PALProps {
  next: () => void;
}

const CATEGORIES2 = [
  {
    id: 298,
    name: "L'enfaon",
    author: "Éric Simard",
    resume:
      "Chaque humanimal est un être unique, mi-enfant mi-animal, doué de capacités extraordinaires. Ils sont élevés ensemble dans des CHGM (Centre des Humains Génétiquement Modifiés). L'un d'eux, L'enfaon, a été accepté dans une école « normale », c'est-à-dire composée uniquement d'humains. Mais attiré par la forêt, il oublie souvent d’écouter la maîtresse. Heureusement, dans la classe, Leïla est amoureuse de lui.",
    me: "Éditions Syros",
    tags: [
      { type: "age", name: "7 ans" },
      { type: "lang", name: "🇫🇷" },
      { type: "interet", name: "Reco. Éducation Nationale" },
      { type: "interet", name: "Diversité - Roman" },
      { type: "interet", name: "Accessibilité 2" },
      { type: "interet", name: "Ouverture pédagogique" },
      { type: "interet", name: "Personnage type" },
      { type: "interet", name: "Équilibre - Contemporain" },
      { type: "genre", name: "Tranche de vie" },
      { type: "genre", name: "Romance" },
    ],
    path: require("../../assets/series/enfaon.jpg"),
  },
  {
    id: 135,
    name: "Vivien drôle de chien",
    author: "Anne-Marie Desplats Duc",
    resume:
      "Il m’est arrivé quelque chose d’incroyable ! Vraiment incroyable ! Je veux bien vous le raconter, mais je me demande si vous n’allez pas me prendre pour un fou. Accrochez-vous bien... Et si j’en entends seulement un murmurer : « Non, il exagère, c’est impossible », j’arrête tout !",
    me: "Scrinéo",
    tags: [
      { type: "age", name: "7 ans" },
      { type: "lang", name: "🇫🇷" },
      { type: "interet", name: "Diversité - Roman" },
      { type: "interet", name: "Accessibilité 2" },
      { type: "interet", name: "Personnage type" },
      { type: "interet", name: "Équilibre - Contemporain" },
      { type: "genre", name: "Fantastique" },
      { type: "genre", name: "Aventure" },
      { type: "genre", name: "Voyage" },
    ],
    path: require("../../assets/series/vivien.jpg"),
  },
  {
    id: 345,
    name: "Le Petit Nicolas - Le Bouillon",
    author: "René Goscinny",
    resume:
      "Aujourd'hui, la maîtresse est absente. Le surveillant, surnommé le Bouillon, la remplace. Il désigne Agnan, le premier de la classe, pour nous surveiller, mais, malgré les interventions du Bouillon, la classe a vite tourné au chaos.",
    me: "IMAV Éditions",
    tags: [
      { type: "age", name: "7 ans" },
      { type: "lang", name: "🇫🇷" },
      { type: "interet", name: "Diversité - Roman" },
      { type: "interet", name: "Accessibilité 2" },
      { type: "interet", name: "Personnage type" },
      { type: "interet", name: "Équilibre - Patrimonial" },
      { type: "genre", name: "Tranche de vie" },
      { type: "genre", name: "Comédie" },
    ],
    path: require("../../assets/series/nicolas.jpg"),
  },
  {
    id: 103,
    name: "La Petite Poucette",
    author: "Hans Christian Andersen",
    resume:
      "Poucette est toute petite, minuscule même ! Mais les aventures qui l’attendent sont grandes. Sera-t-elle de taille à affronter les obstacles sur son chemin ?",
    me: "Contes & Mythes",
    tags: [
      { type: "age", name: "7 ans" },
      { type: "lang", name: "🇫🇷" },
      { type: "interet", name: "Diversité - Conte" },
      { type: "interet", name: "Accessibilité 1" },
      { type: "interet", name: "Équilibre - Classique" },
      { type: "genre", name: "Mythes & Légendes" },
      { type: "genre", name: "Voyage" },
    ],
    path: require("../../assets/series/poucette.jpg"),
  },
  {
    id: 320,
    name: "Chien Pourri ! aux jeux olympiques",
    author: "Colas Gutman",
    resume:
      "Pour Josette et son affreux Jojo de petit frère, la vie n’est pas facile. Leur maman est partie et leur père leur met la pression pour qu’ils deviennent de grands sportifs. Pour les aider, Chien Pourri et Chaplapla organisent les Jojolympiques, entre poubelles et terrain vague.",
    me: "IMAV Éditions",
    tags: [
      { type: "age", name: "9 ans" },
      { type: "lang", name: "🇫🇷" },
      { type: "interet", name: "Diversité - Roman" },
      { type: "interet", name: "Accessibilité 3" },
      { type: "interet", name: "Personnage type" },
      { type: "interet", name: "Équilibre - Contemporain" },
      { type: "genre", name: "Comédie" },
    ],
    path: require("../../assets/series/chien-pourri.jpg"),
  },
];

const PAL: FunctionComponent<PALProps> = (props: PALProps) => {
  const { next } = props;

  const [messageApi, contextHolder] = message.useMessage();
  const [selected, setSelected] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Ajouté à la pile à lire",
    });
  };

  const dots = (s: boolean) => {
    return (
      <div
        style={{
          width: 20,
          height: 20,
          background: s ? "#FECF03" : "#3E276F",
          borderRadius: 9999,
        }}
      />
    );
  };

  const addToPAL = async (idSerie: number) => {
    setIsLoading(true);
    await addToReadingList(idSerie);
    success();
    nextSerie();
    setIsLoading(false);
  };

  const nextSerie = () => {
    if (selected + 1 === CATEGORIES2.length) {
      next();
      return;
    }
    setSelected(selected + 1);
  };

  return (
    <div className="main">
      {contextHolder}
      <Flex
        style={{
          width: "100%",
          marginLeft: 30,
          marginTop: 30,
        }}
        align="center"
      >
        <Flex style={{ flexGrow: 1 }} vertical>
          <div className="title" style={{ flexGrow: 1, textAlign: "center" }}>
            Quelles séries vous plairaient pour commencer ?
          </div>
          <Flex
            style={{
              flexGrow: 1,
              justifyContent: "center",
              marginTop: 20,
            }}
            gap={12}
          >
            {CATEGORIES2.map((_, i) => dots(i === selected))}
          </Flex>
        </Flex>
      </Flex>
      <Flex className="wrapper" gap={18}>
        <img
          style={{
            objectFit: "contain",
            width: 300,
            aspectRatio: 777 / 1035,
            borderRadius: 10,
            marginTop: 20,
            marginLeft: 30,
          }}
          src={CATEGORIES2[selected].path}
          alt=""
        />
        <Flex
          style={{
            flexGrow: 1,
            flexDirection: "column",
            marginTop: 20,
            marginRight: 30,
          }}
        >
          <div className="serie-title">{CATEGORIES2[selected].name}</div>
          <div className="resume" style={{ marginTop: 8 }}>
            {CATEGORIES2[selected].resume}
          </div>
          <Flex wrap="wrap" gap={8} style={{ marginTop: 14 }}>
            {CATEGORIES2[selected].tags.map((tag) => (
              <TagSerie name={tag.name} type={tag.type} />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 70,
          marginTop: 40,
        }}
        gap={20}
      >
        <BtnNbk
          style={{ marginTop: 0 }}
          txt="Ajouter à la pile à lire"
          isLoading={isLoading}
          onClick={() => {
            addToPAL(CATEGORIES2[selected].id);
          }}
        />
        <div
          style={{
            height: 40,
            padding: 10,
            background: "#F6F6F6",
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={nextSerie}
        >
          <Icon sx={{ fontSize: 50, color: "black" }}>chevron_right</Icon>
        </div>
      </Flex>
    </div>
  );
};

export default PAL;

import React, { FunctionComponent, useState } from "react";
import { Flex } from "antd";
import { Icon } from "@mui/material";

import BtnNbk from "../../shared/components/BtnNBK";
import TagSerie from "../../shared/components/TagSerie";

import "../css/style.css";
import "../../shared/css/fontStyle.css";

interface PALProps {
  next: () => void;
}

const CATEGORIES2 = [
  {
    title: "LECTURES DEMANDÉES",
    body: "Retrouvez les lectures demandées par l’enseignant de votre enfant dans l’espace Lectures demandées de la bibliothèque Nabook",
    path: require("../../assets/characters/maestro.png"),
    style: {
      marginLeft: -27,
      aspectRatio: 255 / 385,
    },
  },
  {
    title: "LIRE EN LIGNE",
    body: "Les lectures demandées par l’enseignant peuvent être lues en ligne sans créer de compte Nabook.",
    path: require("../../assets/characters/smurf.png"),
    style: {
      marginBottom: 20,
      marginLeft: -100,
      aspectRatio: 1,
    },
  },
  {
    title: "NOTRE CONSEIL",
    body: "Pour une meilleure expérience, nous vous conseillons d’installer l’application mobile Nabook. Elle offre un plus grand choix de livres et un meilleur confort de lecture.\n💡 Utilisez le code élève de votre enfant pour retrouver les lectures demandées.",
    path: require("../../assets/characters/charlotte.png"),
    style: {
      marginLeft: -100,
      marginTop: -100,
      marginBottom: 40,
      aspectRatio: 556 / 463,
    },
  },
];

const CarouselRelative: FunctionComponent<PALProps> = (props: PALProps) => {
  const { next } = props;

  const [selected, setSelected] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dots = (s: boolean) => {
    return (
      <div
        style={{
          width: 20,
          height: 20,
          background: s ? "#FECF03" : "#3E276F",
          borderRadius: 9999,
        }}
      />
    );
  };

  const nextSerie = () => {
    if (selected + 1 === CATEGORIES2.length) {
      next();
      return;
    }
    setSelected(selected + 1);
  };

  return (
    <div className="main">
      <Flex
        style={{
          width: "100%",
          marginLeft: 30,
          marginTop: 10,
        }}
        align="center"
      >
        <Flex style={{ flexGrow: 1 }} vertical>
          <Flex
            style={{
              flexGrow: 1,
              justifyContent: "center",
              marginTop: 20,
              position: "fixed",
              top: 20,
              left: 0,
              right: 0,
            }}
            gap={12}
          >
            {CATEGORIES2.map((_, i) => dots(i === selected))}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        className="wrapper"
        style={{ justifyContent: "center", alignItems: "center" }}
        gap={18}
      >
        <div
          style={{
            background: "#FECF03",
            borderRadius: 8,
            paddingBottom: 12,
            paddingRight: 12,
            marginTop: 12,
            maxWidth: 700,
            height: "100%",
          }}
        >
          <Flex
            style={{
              background: "#3E276F",
              borderRadius: 8,
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 700,
              marginTop: -12,
              marginLeft: -12,
            }}
          >
            <img
              style={{
                objectFit: "contain",
                width: 400,
                ...CATEGORIES2[selected].style,
              }}
              src={CATEGORIES2[selected].path}
              alt=""
            />
            <Flex vertical style={{ marginRight: 40 }}>
              <div className="serie-title" style={{ marginBottom: 8 }}>
                {CATEGORIES2[selected].title}
              </div>
              <div className="resume">{CATEGORIES2[selected].body}</div>
            </Flex>
          </Flex>
        </div>
      </Flex>

      <Flex
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: 60,
        }}
        gap={20}
      >
        <BtnNbk
          style={{ marginTop: 0 }}
          txt={
            selected === CATEGORIES2.length - 1
              ? "Accéder à la bibliothèque"
              : "Suivant"
          }
          isLoading={isLoading}
          onClick={() => {
            nextSerie();
          }}
        />
      </Flex>
    </div>
  );
};

export default CarouselRelative;

import React from "react";
import Markdown from "markdown-to-jsx";

import { BulleV2 } from "../../../shared/types/Bubble";
import { Dico } from "../../../shared/types/Dico";
import DicoButton from "./DicoButton";

type Props = {
  bubble: BulleV2;
  params: {
    fontSize: number;
    color: string;
    isDYS: boolean;
  };
  clickDico: (id: number) => void;
};

const BubbleMarkdown = (props: Props) => {
  const { bubble, params, clickDico } = props;

  const transformToMarkdown = (txtData: {
    text: string;
    dico?: Dico;
    bold?: boolean;
    italic?: boolean;
  }) => {
    if (bubble.type === "speaker") {
      let tmpTxtPart = txtData.text;

      const startsWithSpace = tmpTxtPart.startsWith(" ");
      const endsWithSpace = tmpTxtPart.endsWith(" ");
      const startsWithReturn = tmpTxtPart.startsWith("\n");
      const endsWithReturn = tmpTxtPart.endsWith("\n");

      if (
        tmpTxtPart.trim().length <= 0 &&
        ((!txtData.italic && !txtData.bold) || tmpTxtPart.includes("\n"))
      )
        return tmpTxtPart;
      else if (tmpTxtPart.trim().length > 0) {
        let isDico = false;
        tmpTxtPart = tmpTxtPart.trim();
        if (
          txtData.dico &&
          txtData.dico.id &&
          (txtData.dico.WordWord === tmpTxtPart ||
            txtData.dico.word === tmpTxtPart)
        ) {
          isDico = true;
          tmpTxtPart = `<span><DicoButton id={${txtData.dico.id}}>${tmpTxtPart}</DicoButton></span>`;
        }

        if (txtData.bold && !isDico)
          tmpTxtPart = `**${tmpTxtPart.split("\n").join("**\n**")}**`;

        if (txtData.italic && !isDico)
          tmpTxtPart = `*${tmpTxtPart.split("\n").join("*\n*")}*`;

        // Quand le texte est en italique ou en gras, il y a une espace qui n'est pas représenté en HTML
        // On est obligé d'ajouter un caractère invisible pour dire au compiler de Markdown
        // d'insérer une espace entre les tags tout de même
        // Sans le caractère invisible, l'espace n'est pas affiché mais l'italique et le gras oui
        if (startsWithSpace) tmpTxtPart = `<strong>‎ </strong>${tmpTxtPart}`;
        if (endsWithSpace) tmpTxtPart = `${tmpTxtPart}<strong>‎ </strong>`;

        if (startsWithReturn) tmpTxtPart = `\n${tmpTxtPart}`;
        if (endsWithReturn) tmpTxtPart = `${tmpTxtPart}\n`;

        return tmpTxtPart;
      }
      return tmpTxtPart;
    } else if (bubble.type === "image")
      return `![${new Date()}](${bubble.url})`;
  };

  const prepareTxt = (child: any) => {
    let res = "";

    if (!child || child.length <= 0) return res;

    for (let i = 0; i < child.length; i++) {
      const c = child[i];
      let transformed = transformToMarkdown(c);

      // Quand deux dicos se suivent, il y a une espace qui n'est pas représenté en HTML
      // On est obligé d'ajouter un caractère invisible pour dire au compiler de Markdown
      // d'insérer une espace entre les tags tout de même
      // Sans le caractère invisible, l'espace n'est pas affiché mais les tags oui
      if (transformed && transformed.trim().length <= 0) {
        res += `<strong>‎ </strong>`;
      } else res += transformed;
    }

    // We need to check if this is a bubble dico only
    // As Markdown interpret bubble dico only as <pre><code><DicoButton> ... </DicoButton></code></pre>
    // We need to specify that this is a txt with a p tag
    if (
      res.trim().startsWith("<DicoButton") &&
      res.trim().endsWith("</DicoButton>")
    )
      return `<p>${res}</p>`;

    return res;
  };

  return (
    <div
      style={{
        padding: 12,
        textAlign: "left",
      }}
    >
      <Markdown
        options={{
          renderRule: (next, node) => {
            const punctuation = new RegExp("^[?!:]", "gi");
            if (node.type === "27" && node.text.trim() === "") return <></>;
            if (node.type === "27" && punctuation.test(node.text))
              return <> {node.text}</>;
            if (node.type === "27" && node.text.startsWith("."))
              return <>{node.text}</>;
            return next();
          },
          overrides: {
            DicoButton: {
              component: DicoButton,
              props: {
                clickDico,
                params,
              },
            },
          },
        }}
      >
        {prepareTxt(bubble.children)}
      </Markdown>
    </div>
  );
};

export default BubbleMarkdown;

import { Flex } from "antd";
import { useState } from "react";

import { Assignation } from "../../shared/types/Assignation";
import DefaultCoverV2 from "../../shared/cover/DefaultCoverV2";
import PanelAssignation from "./PanelAssignation";
import { CircularProgress } from "@mui/material";

interface DashboardVisualProps {
  series: Assignation[];
  isLoading: boolean;
}

const SerieRow = (props: {
  data: Assignation;
  onClick: (data: Assignation) => void;
}) => {
  const { data, onClick } = props;
  return (
    <Flex
      onClick={() => {
        onClick(data);
      }}
      style={{
        background: "#3E276F",
        borderRadius: 8,
        cursor: "pointer",
      }}
      vertical={false}
      gap={14}
    >
      <DefaultCoverV2
        onClickCover={() => {
          onClick(data);
        }}
        style={{ margin: 0 }}
        key={data.serie.uid}
        serie={data.serie}
      />
      <Flex vertical gap={10}>
        <div
          style={{
            height: "100%",
            background: "#291D5B",
            borderRadius: 8,
            marginTop: 14,
            marginRight: 14,
            padding: 8,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: "white",
                fontSize: 22,
                fontFamily: "Roboto",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              {data.countAssignement}
            </div>
            <div
              style={{
                color: "white",
                fontSize: 12,
                fontFamily: "Roboto",
                fontWeight: "400",
                wordWrap: "break-word",
                marginTop: 4,
              }}
            >
              assignations en cours
            </div>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            background: "#291D5B",
            borderRadius: 8,
            marginBottom: 14,
            marginRight: 14,
            padding: 8,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: "white",
                fontSize: 12,
                fontFamily: "Roboto",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Première assignation
            </div>
            <div
              style={{
                color: "white",
                fontSize: 22,
                fontFamily: "Roboto",
                fontWeight: "700",
                wordWrap: "break-word",
                marginTop: 4,
              }}
            >
              {data.createAt}
            </div>
          </div>
        </div>
      </Flex>
    </Flex>
  );
};

const DashboardVisual = (props: DashboardVisualProps) => {
  const { isLoading, series } = props;

  const [openPanelSerie, setOpenPanelSerie] = useState(false);
  const [selectedAssignation, setSelectedAssignation] =
    useState<Assignation | null>(null);

  const clickSerie = (data: Assignation) => {
    setOpenPanelSerie(true);
    setSelectedAssignation(data);
  };

  if (isLoading)
    return (
      <Flex
        style={{
          flex: 1,
          height: "80vh",
        }}
        justify="center"
        align="center"
      >
        <CircularProgress style={{ color: "white" }} />
      </Flex>
    );

  if (series.length <= 0) {
    return (
      <div
        className="serie-title"
        style={{
          fontSize: 24,
        }}
      >
        Aucune série n'a encore été assignée
      </div>
    );
  }

  return (
    <>
      <div style={{ marginLeft: 20, marginTop: 50 }}>
        <div className="serie-title" style={{ marginBottom: 10 }}>
          LECTURES ASSIGNÉES
        </div>
        <Flex wrap="wrap" gap={30}>
          {series.map((s, i) => (
            <SerieRow key={i} data={s} onClick={clickSerie} />
          ))}
        </Flex>
      </div>
      {openPanelSerie && selectedAssignation ? (
        <div
          style={{
            height: "88%",
            backgroundColor: "white",
            position: "absolute",
            bottom: -10,
            left: 0,
            right: 0,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            overflow: "scroll",
          }}
        >
          <PanelAssignation
            data={selectedAssignation}
            onClose={() => {
              setOpenPanelSerie(false);
              setSelectedAssignation(null);
            }}
          />{" "}
        </div>
      ) : null}
    </>
  );
};

export default DashboardVisual;

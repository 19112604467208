import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Icon } from "@mui/material";
import { Avatar, Flex } from "antd";

import { Episode } from "../../shared/types/Episode";
import { Serie } from "../../shared/types/Serie";
import { getPreviewAssignation } from "../../shared/api/assignationRequest";
import { useAuth } from "../../shared/context/TokenContext";

interface EpisodeRowProps {
  episode: Episode;
  serie: Serie;
  seasonNumber: number;
  assign: (idEpisode: number) => void;
  onClick: (episode: Episode, idSerie: number) => void;
}

const EpisodeRow: FunctionComponent<EpisodeRowProps> = (
  props: EpisodeRowProps
) => {
  const { episode, serie, seasonNumber, assign, onClick } = props;
  console.log("🛟 ~ episode:", episode);

  const { idClasse, isEdifice, isTeacher } = useAuth();

  const [assignements, setAssignements] = useState([]);
  const [count, setCount] = useState(0);

  const loadPreviewAssignement = useCallback(async () => {
    if (idClasse) {
      const res = await getPreviewAssignation(episode.id, idClasse);
      if (res.students.length > 0) setAssignements(res.students);
      setCount(res.count);
    }
  }, [episode, idClasse]);

  useEffect(() => {
    loadPreviewAssignement();
  }, [loadPreviewAssignement]);

  const getEpState = () => {
    if (episode.EpLus.length <= 0) return "play";

    const epLus = episode.EpLus[0];
    const finished = epLus.finished;

    if (finished !== null && finished !== undefined) {
      if (finished === true) return "finished";

      return "pause";
    }
    return "play";
  };

  const getIconEp = () => {
    const state = getEpState();
    switch (state) {
      case "pause":
        return "pause";
      case "finished":
        return "done";
      case "play":
      default:
        return "play_arrow";
    }
  };

  const getButtonStyle = () => {
    const state = getEpState();
    switch (state) {
      case "pause":
        return {
          background: "#ffd23f",
          border: "3px rgba(242, 185, 0, 1) solid",
        };
      case "finished":
        return {
          background: "green",
          border: "3px green solid",
        };
      case "play":
      default:
        return {
          backgroundColor: "#FF6501",
          border: "3px solid #B14E0E",
        };
    }
  };

  return (
    <Flex gap={30}>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          onClick(episode, serie.id);
        }}
      >
        <div
          className="episode-name"
          style={{ color: "#120D35", marginBottom: 8 }}
        >
          {`EP${episode.number} - ${episode.name}`}
        </div>
        <div id="containerEpRow">
          <div id="imgContainerEpRow">
            <img
              style={{
                objectFit: "contain",
                aspectRatio: 750 / 300,
                width: "100%",
              }}
              src={`https://newbrand.s3.eu-west-3.amazonaws.com/series/${
                serie.uid
              }/season-${seasonNumber + 1}/ep-${episode.number}/img/${
                episode.img
              }`}
              alt=""
            />
          </div>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 50,
                borderRadius: 100,
                ...getButtonStyle(),
              }}
            >
              <Icon sx={{ fontSize: 40, color: "white" }}>{getIconEp()}</Icon>
            </div>
          </div>
        </div>
      </div>
      {isEdifice && isTeacher && isEdifice() && isTeacher() ? (
        <Flex justify="center" align="center" vertical gap={12}>
          {assignements.length > 0 ? (
            <Flex>
              <Avatar.Group>
                {assignements.map((a, i) => {
                  if (i % 2 === 0)
                    return (
                      <Avatar
                        style={{
                          backgroundColor: "#FFD8BF",
                          color: "#FA541C",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          wordWrap: "break-word",
                        }}
                      >
                        {a}
                      </Avatar>
                    );
                  else
                    return (
                      <Avatar
                        style={{
                          backgroundColor: "#FF7A45",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          wordWrap: "break-word",
                        }}
                      >
                        {a}
                      </Avatar>
                    );
                })}
                {count > 0 ? (
                  <Avatar
                    style={{
                      backgroundColor:
                        assignements.length % 2 === 0 ? "#FFD8BF" : "#FF7A45",
                      color:
                        assignements.length % 2 === 0 ? "#FA541C" : "white",
                      fontSize: 16,
                      fontFamily: "Roboto",
                      wordWrap: "break-word",
                    }}
                  >
                    +{count}
                  </Avatar>
                ) : null}
              </Avatar.Group>
            </Flex>
          ) : null}

          <Flex
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              assign(episode.id);
            }}
            style={{
              background: "#FFD8BF",
              borderRadius: 8,
              border: "3px #FF6501 solid",
              padding: 8,
              cursor: "pointer",
            }}
            justify="center"
            align="center"
            gap={10}
          >
            <Icon sx={{ color: "#FF6501", fontSize: 30 }}>
              add_box_outlined_icon
            </Icon>
            <div
              className="resume"
              style={{
                color: "#FF6501",
                fontSize: 14,
              }}
            >
              Assigner l’épisode
            </div>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default EpisodeRow;

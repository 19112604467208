import React from "react";
import "../../shared/css/basicStyles.css";
import "../css/Reader.css";
import { Character } from "../../shared/types/Character";
import { Typography } from "@mui/material";

type Props = {
  characters: Character[];
};

const ListCharacters = (props: Props) => {
  const { characters } = props;

  const renderRow = (c: Character) => {
    const isNarrateur =
      (c.displayName?.toUpperCase() || c.name.toUpperCase()) === "NARRATEUR";

    return (
      <div
        style={{
          backgroundColor: "white",
          maxWidth: "90%",
          width: "100%",
          alignSelf: "center",
          marginTop: 10,
          marginBottom: 10,
          padding: 10,
          borderRadius: 10,
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: c.img ? "3rem" : "0rem",
                  aspectRatio: 1,
                }}
              >
                <img
                  style={{
                    height: "100%",
                    aspectRatio: 1,
                    objectFit: "contain",
                  }}
                  src={`https://newbrand.s3.eu-west-3.amazonaws.com/personnages/${c.img}`}
                  alt=""
                />
              </div>
              <div
                style={{
                  backgroundColor: c.color ? c.color : "#E8E8E8",
                  borderRadius: 15,
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingTop: 4,
                  paddingBottom: 4,
                  marginLeft: 5,
                  width: "100%",
                }}
              >
                <p
                  style={{
                    color: isNarrateur
                      ? "#333333"
                      : c.color
                      ? "#F1F1F1"
                      : "#333333",
                    fontFamily: "LuckiestGuy",
                    textAlign: "center",
                  }}
                >
                  {c.displayName?.toUpperCase() || c.name.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 15,
            }}
          >
            <div
              style={{
                flex: 1,
                marginRight: 5,
              }}
            >
              <p
                style={{
                  color: "black",
                  alignSelf: "center",
                  marginBottom: 5,
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Bulles
              </p>
              <div
                style={{
                  backgroundColor: "#2b1a59",
                  borderRadius: 10,
                  padding: 4,
                }}
              >
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: "1rem",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  {c.bulles}
                </p>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: 5,
              }}
            >
              <p
                style={{
                  color: "black",
                  alignSelf: "center",
                  marginBottom: 5,
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Dico
              </p>
              <div
                style={{
                  backgroundColor: "#2b1a59",
                  borderRadius: 10,
                  padding: 4,
                }}
              >
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: "1rem",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  {c.definitions}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <Typography style={{ marginBottom: 24 }}>
        Liste des personnages de cet épisode
      </Typography>
      {characters.map((c) => renderRow(c))}
    </div>
  );
};

export default ListCharacters;

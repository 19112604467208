import Cookies from "js-cookie";
import { BASE_URL } from "./constants";
import axios from "axios";

export async function getHome(type: string) {
  const token = Cookies.get("user");
  try {
    const res = await axios.get(`${BASE_URL}/main/home/v3?t=${type}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (e) {
    console.log(`[GET_DETAILS_COLLECTION] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function getSerieInfo(idSerie: string | undefined) {
  try {
    const token = Cookies.get("user");
    const response = await axios.get(`${BASE_URL}/main/serie/${idSerie}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function addToReadingList(idSerie: number) {
  const token = Cookies.get("user");
  try {
    const res = await axios.post(
      `${BASE_URL}/main/home/list/add`,
      {
        idSerie,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[GET_DETAILS_COLLECTION] Il y a eu un problème : ${e}`);
    return null;
  }
}

import { useCallback, useEffect, useState } from "react";
import { Flex } from "antd";
import { Icon } from "@mui/material";

import { getHome } from "../../../shared/api/serieRequest";

import { Category } from "../../../shared/types/Category";
import { Serie } from "../../../shared/types/Serie";
import HomeVisual from "../../components/v2/HomeVisual";
import PanelSerie from "../../../series/screen/PanelSerie";

import "../../../shared/css/basicStyles.css";
import "../../../shared/css/fontStyle.css";
import HeaderV2 from "../../../shared/components/HeaderV2";
import { useAuth } from "../../../shared/context/TokenContext";
import { unlockSerieProf } from "../../../shared/api/request";

const Home = () => {
  const { isEdifice, isTeacher } = useAuth();

  const [categories, setCategories] = useState<Category[]>([]);
  const [playlist, setPlaylist] = useState<Serie[]>([]);

  const [selectedSerie, setSelectedSerie] = useState<Serie | null>(null);
  const [openPanelSerie, setOpenPanelSerie] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [msgErr, setMsgErr] = useState("");

  const loadLib = useCallback(async () => {
    const type = isTeacher && isTeacher() ? "prof" : "user";
    const res = await getHome(type);
    if (res) {
      setCategories(res.cats);
      setPlaylist(res.playlist);
    }
    setIsLoading(false);
  }, [isTeacher]);

  const onClickSerie = (serie: Serie) => {
    console.log("clicked");
    setSelectedSerie(serie);
    setOpenPanelSerie(true);
  };

  const onUnlockSerie = async (code: string) => {
    setMsgErr("");
    const res = await unlockSerieProf(code);
    if (!res.isOk) setMsgErr(res.msg);
    else window.location.reload();
  };

  useEffect(() => {
    loadLib();
  }, [loadLib]);

  return (
    <div className="mainContainer">
      <HeaderV2
        selected="home"
        type={
          isEdifice && isEdifice()
            ? isTeacher && isTeacher()
              ? "teacher"
              : "edifice-user"
            : "user"
        }
      />
      <HomeVisual
        cats={categories}
        isLoading={isLoading}
        msgErr={msgErr}
        playlist={playlist}
        onClickSerie={onClickSerie}
        onUnlockSerie={onUnlockSerie}
        isPanelOpen={openPanelSerie && selectedSerie !== null}
      />
      {openPanelSerie && selectedSerie !== null ? (
        <Flex>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenPanelSerie(false);
              setSelectedSerie(null);
            }}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              height: "100vh",
              alignItems: "flex-end",
              justifyContent: "center",
              display: "flex",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <div
              onClick={() => {
                setOpenPanelSerie(false);
                setSelectedSerie(null);
              }}
              style={{
                position: "absolute",
                top: 20,
                right: 20,
                cursor: "pointer",
                backgroundColor: "white",
                width: 50,
                height: 50,
                borderRadius: 100,
                padding: 10,
              }}
            >
              <Icon sx={{ fontSize: 50, color: "black" }}>close_rounded</Icon>
            </div>
          </div>
          <div
            style={{
              height: "88%",
              backgroundColor: "white",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              overflow: "scroll",
              zIndex: 100,
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              WebkitTransform: "translate3d(0, 0, 0)",
            }}
          >
            <PanelSerie
              close={() => {
                setOpenPanelSerie(false);
                setSelectedSerie(null);
              }}
              serie={selectedSerie}
            />
          </div>
        </Flex>
      ) : null}
    </div>
  );
};

export default Home;

import { FunctionComponent } from "react";

interface TagSerieProps {
  name: string;
  type: string;
  fontSize?: number;
}

const TagSerie: FunctionComponent<TagSerieProps> = (props: TagSerieProps) => {
  const { fontSize, name, type } = props;
  return (
    <div
      style={{
        margin: 5,
        padding: 10,
        backgroundColor: type === "genre" ? "#FECF03" : "#3E276F",
        borderRadius: 100,
      }}
    >
      <div
        style={{
          color: type === "genre" ? "#000" : "#FFF",
          fontSize: fontSize || 16,
          fontFamily: "Poppins-Regular",
          wordWrap: "break-word",
        }}
      >
        {name}
      </div>
    </div>
  );
};

export default TagSerie;

import { FunctionComponent, useEffect, useState } from "react";
import { Icon } from "@mui/material";
import { Flex, message } from "antd";

import { postBulkAssignStudent } from "../../shared/api/assignationRequest";
import { Serie } from "../../shared/types/Serie";
import { getSerieInfo } from "../../shared/api/serieRequest";
import DefaultCoverV2 from "../../shared/cover/DefaultCoverV2";
import TagSerie from "../../shared/components/TagSerie";

import EpisodeRow from "../components/EpisodeRow";
import ModalAssignements from "../components/ModalAssignements";
import { useAuth } from "../../shared/context/TokenContext";

import "../css/PageSerie.css";
import { Episode } from "../../shared/types/Episode";
import { useNavigate } from "react-router-dom";
import ModalEpisodeEdifice from "../components/ModalEpisodeEdifice";

interface PanelSerieProps {
  serie: Serie;
  close: () => void;
}

const PanelSerie: FunctionComponent<PanelSerieProps> = (
  props: PanelSerieProps
) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { idClasse, isEdifice, isTeacher } = useAuth();
  const navigation = useNavigate();

  const [details, setDetails] = useState<Serie | undefined>(undefined);
  const [assignEp, setAssignEp] = useState<number | null>(null);

  const [infoEp, setInfoEp] = useState<any>(null);
  const [openModalEdifice, setOpenModalEdifice] = useState(false);

  useEffect(() => {
    const loadDetails = async () => {
      const res = await getSerieInfo(`${props.serie.id}`);
      setDetails(res);
    };
    loadDetails();
  }, [props.serie.id]);

  const openAssignModal = (idEpisode: number) => {
    setAssignEp(idEpisode);
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "L'assignation a été effectuée avec succès",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content:
        "Il y a eu un problème lors de l'assignation. Veuillez réessayer",
    });
  };

  const openEpisode = (episode: Episode, idSerie: number) => {
    navigation(
      `/reader/${episode.id}/${
        episode.origStartingNode
          ? episode.origStartingNode
          : episode.startingNode
      }`,
      {
        state: {
          idSerie: idSerie,
          episode: episode,
          params: { isDys: false, fontSize: 1 },
        },
      }
    );
  };

  return (
    <div>
      {contextHolder}
      <Flex style={{ padding: 30 }}>
        <Flex>
          <DefaultCoverV2 serie={details || props.serie} />
        </Flex>
        <Flex
          vertical
          style={{
            marginLeft: 10,
            marginTop: 10,
            marginBottom: 10,
            flexGrow: 1,
            flex: 1,
          }}
        >
          <div className="serie-title" style={{ color: "#120D35" }}>
            {details?.name}
          </div>
          <Flex>
            <TagSerie fontSize={12} name={`${details?.age} ans`} type="autre" />
            <TagSerie
              fontSize={12}
              name={`${details?.lang === "fr" ? "🇫🇷" : "🇫🇷🇬🇧"}`}
              type="autre"
            />
          </Flex>
          <Flex>
            <TagSerie
              fontSize={12}
              name={`Écrit par ${details?.author}`}
              type="genre"
            />
            <TagSerie
              fontSize={12}
              name={`Illustré par ${details?.illustrator}`}
              type="genre"
            />
          </Flex>
          <div
            className="resume"
            style={{ marginTop: 8, color: "#120D35", flex: 1 }}
          >
            {details?.Seasons[0].summary}
          </div>
        </Flex>
      </Flex>
      <Flex vertical gap={14} style={{ padding: 30, maxWidth: 900 }}>
        {details?.Seasons &&
          details.Seasons[0] &&
          details.Seasons[0].Episodes &&
          details.Seasons[0].Episodes.map((ep) => (
            <EpisodeRow
              episode={ep}
              serie={details}
              seasonNumber={0}
              assign={openAssignModal}
              onClick={(episode: Episode, idSerie: number) => {
                setInfoEp({ episode, idSerie });
                if (isEdifice && !isEdifice()) openEpisode(episode, idSerie);
                else if (isEdifice && isEdifice()) {
                  if (isTeacher && !isTeacher()) setOpenModalEdifice(true);
                  else openEpisode(episode, idSerie);
                }
              }}
            />
          ))}
      </Flex>

      <ModalAssignements
        open={assignEp !== null}
        idEpisode={assignEp}
        assign={async (students: any) => {
          if (idClasse) {
            const status = await postBulkAssignStudent(
              assignEp!,
              idClasse,
              students
            );

            if (status && status === 200) {
              success();
              setAssignEp(null);
            } else error();
          } else error();
        }}
        close={() => setAssignEp(null)}
      />
      <ModalEpisodeEdifice
        episode={infoEp?.episode}
        idSerie={infoEp?.idSerie}
        open={openModalEdifice && infoEp && infoEp.episode}
        onCancel={() => setOpenModalEdifice(false)}
      />
    </div>
  );
};

export default PanelSerie;

import { useState } from "react";

import PAL from "../components/PAL";
import { useNavigate } from "react-router-dom";
import IntroTeacher from "../components/IntroTeacher";

const OnboardingTeacher = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  if (step === 0)
    return (
      <IntroTeacher
        next={() => {
          setStep(step + 1);
        }}
      />
    );

  if (step === 1)
    return (
      <PAL
        next={() => {
          navigate("/v2/home");
        }}
      />
    );
};

export default OnboardingTeacher;

import { FunctionComponent } from "react";

import BtnNbk from "../../shared/components/BtnNBK";

import "../css/style.css";
import "../../shared/css/fontStyle.css";
import { Flex } from "antd";

interface IntroProps {
  next: () => void;
}

const IntroTeacher: FunctionComponent<IntroProps> = (props: IntroProps) => {
  const { next } = props;
  return (
    <div className="main">
      <Flex
        style={{
          width: "100%",
          marginLeft: 30,
          marginTop: 30,
        }}
        align="center"
      >
        <Flex
          style={{ flexGrow: 1, position: "fixed", top: 40, left: 0, right: 0 }}
          vertical
        >
          <Flex
            style={{
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <div className="title">Bienvenue sur Nabook !</div>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        className="wrapper"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div
          style={{
            background: "#FECF03",
            borderRadius: 8,
            paddingBottom: 12,
            paddingRight: 12,
            maxWidth: 700,
            height: "100%",
          }}
        >
          <Flex
            style={{
              background: "#3E276F",
              borderRadius: 8,
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 700,
              marginTop: -12,
              marginLeft: -12,
            }}
          >
            <img
              style={{
                objectFit: "contain",
                width: 400,

                marginLeft: -27,
                aspectRatio: 255 / 385,
              }}
              src={require("../../assets/characters/maestro.png")}
              alt=""
            />
            <Flex vertical style={{ marginRight: 40 }}>
              <div className="serie-title" style={{ marginBottom: 8 }}>
                QUART D’HEURE LECTURE
              </div>
              <div className="resume">
                Animez des temps de lecture à voix haute dans votre classe.
                projetez l’écran au tableau répartissez les locuteurs à
                interpréter toute la classe suit la lecture !
              </div>
            </Flex>
          </Flex>
        </div>
      </Flex>
      <BtnNbk txt="Constituer ma pile à lire" onClick={next} />
    </div>
  );
};

export default IntroTeacher;
